<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">档案管理1111</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">一期一档</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">档案目录</a>
        </span>
      </div>
      <div class="framePage-body">
        <div
          class="operationControl flexdc"
          style="align-items: flex-start; padding-bottom: 0"
        >
          <div class="searchbox" style="margin-bottom: 15px">
            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="handldownload()"
              >下载全部档案</el-button
            >
          </div>
        </div>
        <p style="padding: 8px 10px">
          <span style="margin-right: 15px"
            >企业名称：{{ payload.enterpriseName }}</span
          >
          <span style="margin-right: 15px"
            >班级名称：{{ payload.projectName }}</span
          >
          <span>期名称：{{ payload.phaesName }}</span>
        </p>
        <div class="framePage-scroll">
          <el-table
            ref="multipleTable"
            :data="tableData"
            :height="tableHeight"
            size="small"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="tableHeader"
            stripe
          >
            <el-table-column
              label="目录"
              align="left"
              prop="dictValue"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.dictValue }}
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="120px">
              <template slot-scope="scope" class="flexcc">
                <el-button
                  type="text"
                  style="padding: 0px 5px"
                  size="mini"
                  @click="seeInfo(scope.row)"
                  v-if="scope.row.dictCode == '305'"
                  >查看</el-button
                >
                <el-button
                  type="text"
                  style="padding: 0px 5px"
                  size="mini"
                  @click="handlEdit(scope.row)"
                  >{{
                    scope.row.dictCode == "305" ? "生成档案" : "编辑"
                  }}</el-button
                >
              </template>
            </el-table-column>
            <Empty slot="empty" />
          </el-table>
        </div>
      </div>
    </div>
    <ArchivesOfpaper ref="ref_315" />
    <PerformanceAssessment ref="ref_320" />
    <SafetyTrainingSignUp ref="ref_305" />
    <TrainDate ref="ref_300" />
    <SafetyTrainingHS ref="ref_340" />
    <SafetyTrainingHandout ref="ref_350" />
    <TrainOfVideo ref="ref_330" />
    <TrainOfVideo2 ref="ref_370" />
    <ContractOrCopy ref="ref_335" />
    <AssessmentReport ref="ref_310" />
    <!-- 305 - 预览 -->
    <el-dialog
      :visible.sync="loading_305"
      title="预览档案"
      top="5%"
      width="50%"
      :center="true"
    >
      <div class="archives_box">
        <div class="df" style="justify-content: flex-end; margin-bottom: 10px">
          <el-button
            class="bgc-bv"
            style="margin: 0 10px"
            size="mini"
            @click="downLoad"
            >下载</el-button
          >
        </div>
        <div id="pdf-content1" style="height: 600px"></div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ArchivesOfpaper from "@/components/oneDateCatalog/archivesOfpaper.vue"; // 培训考核试卷
import PerformanceAssessment from "@/components/oneDateCatalog/PerformanceAssessment.vue"; // 学员考核成绩统计表
import SafetyTrainingSignUp from "@/components/oneDateCatalog/SafetyTrainingSignUp.vue"; // 安全培训教育记录及签字表
import TrainDate from "@/components/oneDateCatalog/trainDate.vue"; //  培训日程安排通知/也可称为培训计划
import SafetyTrainingHS from "@/components/oneDateCatalog/SafetyTrainingHS.vue"; //   安全培训教材会审表
import SafetyTrainingHandout from "@/components/oneDateCatalog/SafetyTrainingHandout.vue"; // 安全培训教材或课程讲义
import TrainOfVideo from "@/components/oneDateCatalog/TrainOfVideo.vue"; // 培训影像资料
import TrainOfVideo2 from "@/components/oneDateCatalog/TrainOfVideo2.vue"; // 培训影像资料
import ContractOrCopy from "@/components/oneDateCatalog/ContractOrCopy.vue"; // 与培训机构签订的服务合同或者协议复印件
import AssessmentReport from "@/components/oneDateCatalog/AssessmentReport.vue"; // 本期综合考评报告
import Empty from "@/components/Empty.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import pdf from "pdfobject";
export default {
  name: "CatalogueOfArchives",
  components: {
    Empty,
    ArchivesOfpaper,
    PerformanceAssessment,
    SafetyTrainingSignUp,
    TrainDate,
    SafetyTrainingHS,
    SafetyTrainingHandout,
    TrainOfVideo,
    TrainOfVideo2,
    ContractOrCopy,
    AssessmentReport,
  },
  mixins: [List],
  data() {
    return {
      dialogVisible: true,
      tableData: [],
      // 接口参数,传到下一个页面
      payload: {},
      // 305 - loading
      loading_305: false,
      // 305 - 下载pdf的路径
      downLoadUrl: "",
    };
  },
  created() {
    this.getTableHeight();
    this.payload = JSON.parse(this.$route.query.payload);
    this.queryList();
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  methods: {
    // 查询档案目录
    queryList() {
      this.$post("/dm/phase/record/category/list").then((res) => {
        // ;
        this.tableData = res.data || [];
      });
    },
    // 查看
    seeInfo(row) {
      let params = {
        enterpriseId: this.payload.enterpriseId,
        phaseId: this.payload.phaseId,
      };
      this.$post("/biz/dm/safetyTrainSign/check", params)
        .then((res) => {
          if (res.data) {
            this.loading_305 = true;
            this.downLoadUrl = res.data.downloadUrl;
            this.showUrl = res.data.showUrl;
            this.$nextTick(() => {
              pdf.embed(res.data.showUrl, "#pdf-content1", {
                pdfOpenParams: {
                  scrollbars: "0",
                  toolbar: "0",
                  statusbar: "1",
                },
              });
            });
          } else {
            this.$message.warning(res.message);
            this.loading_305 = false;
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 下载
    downLoad() {
      window.open(this.downLoadUrl);
    },
    // 编辑/生成档案
    handlEdit(row) {
      // 判断类型 -
      if (row.dictCode == "305") {
        let parmar = {
          phaseId: this.payload.phaseId,
          projectId: this.payload.projectId,
        };
        this.$post("/biz/dm/safetyTrainSign/download", parmar)
          .then((res) => {
            if (res.status == "0") {
              let list = res.data;
              for (let item of list) {
                if (!this.downloadItems.includes(item.taskId)) {
                  this.$store.dispatch("pushDownloadItems", item.taskId);
                } else {
                  this.$message.warning(
                    "[" + item.fileName + "]已经申请下载,请耐心等待"
                  );
                }
              }
              this.closeDocsDialog();
            } else {
              this.$message.error(res.message);
            }
          })
          .catch((err) => {
            return;
          });
      } else {
        let params = {
          ...row,
          ...this.payload,
        };
        params = JSON.stringify(params);
        this.$refs["ref_" + row.dictCode].showPop(params);
      }
    },
    // 下载全部档案
    handldownload() {
      let parmar = {
        enterpriseId: this.payload.enterpriseId,
        phaseId: this.payload.phaseId,
        projectId: this.payload.projectId,
        compId: this.payload.compId,
      };
      this.$post("/dm/phase/record/phase/download", parmar)
        .then((res) => {
          if (res.status == "0") {
            for (let item of res.data) {
              if (!this.downloadItems.includes(item.taskId)) {
                this.$store.dispatch("pushDownloadItems", item.taskId);
              } else {
                this.$message.warning(
                  "[" + item.fileName + "]已经申请下载,请耐心等待"
                );
              }
            }
            this.closeDocsDialog();
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          return;
        });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 2.55) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40;
      }
      if (page) {
        tHeight -= 25;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh) {
          // this.getData(-1);
        } else {
          // this.getData();
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less">
.dialogBox {
  height: 600px;
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.75rem 0;
    h3 {
      padding-bottom: 0.75rem;
    }
  }
  .divBox2 {
    width: 100%;
  }
}
tr {
  height: 32px;
}
</style>
