// 安全培训教材或课程讲义
<template>
  <el-dialog
    :visible.sync="dialogVisible"
    title="安全培训教材/课程讲义"
    top="5%"
    width="50%"
    :modal="false"
    :center="true"
    :before-close="doClose"
  >
    <div class="archives_box">
      <div class="df" style="justify-content: flex-end; margin-bottom: 10px">
        <el-button
          class="bgc-bv"
          style="margin: 0 10px"
          size="mini"
          @click="handlUpdate()"
          >更新数据</el-button
        >
        <el-button
          class="bgc-bv"
          style="margin: 0 10px"
          size="mini"
          @click="doDownload()"
          >下载</el-button
        >
      </div>
      <div id="pdf-content6" style="height: 600px"></div>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import pdf from "pdfobject";
export default {
  name: "SafetyTrainingHandout",
  props: [],
  data() {
    return {
      dialogVisible: false,
      downloadUrl: "",
      updated: 0,
    };
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  methods: {
    showPop(res) {
      this.playload = JSON.parse(res);
      this.queryData();
    },
    // 更新
    handlUpdate() {
      this.updated = 1;
      this.queryData();
    },
    // 查询list
    queryData() {
      let params = {
        enterpriseId: this.playload.enterpriseId,
        phaseId: this.playload.phaseId,
        projectId: this.playload.projectId,
        compId: this.playload.compId,
        updated: this.updated,
      };
      this.$post("/documentcenter/getSafetyMaterials", params)
        .then((ret) => {
          this.dialogVisible = true;
          this.updated = 0;
          this.downloadUrl = ret.data.downloadUrl
          this.$nextTick(() => {
            pdf.embed(ret.data.viewUrl, "#pdf-content6", {
              pdfOpenParams: {
                scrollbars: "0",
                toolbar: "0",
                statusbar: "1",
              },
            });
          });
        })
        .catch((err) => {
          this.dialogVisible = false;
        });
    },
    // 下载全部
    doDownload() {
      window.open(this.downloadUrl)
    },
    doClose() {
      this.dialogVisible = false;
      this.downloadUrl = ''
    },
  },
};
</script>

<style lang="less">
.el-dialog--center .el-dialog__body {
  padding: 20px;
}
.upload {
  .uploadvideo {
    display: flex;
    .el-upload {
      height: auto !important;
      border: none;
    }
  }
  .el-button {
    height: auto !important;
  }
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 180px;
  font-size: 14px;
}
// .active {
//   background-color: #d7d7d7;
// }
.data_box {
  height: 500px;
}
</style>
