// 一期一档考核试卷
<template>
  <el-dialog
    :visible.sync="dialogVisible"
    title="培训考核试卷"
    top="5%"
    width="50%"
    :center="true"
    :before-close="doClose"
  >
    <div class="archives_box">
      <div class="df" style="justify-content: flex-end; margin-bottom: 10px">
        <el-button
          class="bgc-bv"
          style="margin: 0 10px"
          size="mini"
          @click="handlUpdate()"
          >更新数据</el-button
        >
        <el-button
          class="bgc-bv"
          style="margin: 0 10px"
          size="mini"
          @click="doDownload()"
          >下载</el-button
        >
      </div>
      <div id="pdf-content1" style="height: 600px"></div>
    </div>
  </el-dialog>
</template>

<script>
import pdf from "pdfobject";
export default {
  name: "archivesOfPaper",
  props: [],
  data() {
    return {
      dialogVisible: false,
      updated: 0,
    };
  },
  methods: {
    showPop(res) {
      this.playload = JSON.parse(res);
      this.queryData();
    },
    queryData() {
      let params = {
        phaseId: this.playload.phaseId,
        compId: this.playload.compId,
        updated: this.updated,
      };
      this.$post("/documentcenter/getTrainExamPaper", params)
        .then((res) => {
          if (res.status == "0") {
            this.dialogVisible = true;
            this.updated = 0;
            this.$nextTick(() => {
              pdf.embed(res.data.viewUrl, "#pdf-content1", {
                pdfOpenParams: {
                  scrollbars: "0",
                  toolbar: "0",
                  statusbar: "1",
                },
              });
            });
          } else {
            this.dialogVisible = false;
          }
        })
        .catch(() => {
          this.dialogVisible = false;
        });
    },
    handlUpdate() {
      this.updated = 1;
      this.queryData();
    },
    doDownload() {
      this.$post("/documentcenter/getTrainExamPaper", {
        phaseId: this.playload.phaseId,
        compId: this.playload.compId,
        updated: 0,
      }).then((res) => {
        // ;
        if (res.status == "0") {
          let url = res.data.downloadUrl || "";
          window.open(url);
        }
      });
    },
    doClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style>
</style>