//安全培训教材会审表
<template>
  <el-dialog
    :visible.sync="dialogVisible"
    title="安全培训教材会审"
    top="5%"
    width="70%"
    :center="true"
    :before-close="doClose"
  >
    <div class="archives_box">
      <div class="df" style="justify-content: flex-end; margin-bottom: 10px">
        <div class="flexca">
          <p>仅支持上传pdf格式的文件</p>
        </div>
        <el-button
          class="bgc-bv"
          style="margin-left: 10px"
          size="mini"
          @click="downloadTemp()"
          >下载模板</el-button
        >
        <div class="upload" style="margin-left: 10px">
          <el-upload
            class="uploadvideo"
            multiple
            accept=".pdf"
            :auto-upload="true"
            :show-file-list="false"
            :on-exceed="onExceed"
            :on-change="handleAvatarSuccess2"
            :before-upload="beforeAvatarUpload2"
            :http-request="$requestMine"
            action
          >
            <el-button
              size="mini"
              style="font-size: 12px; padding: 10px"
              slot="trigger"
              class="bgc-bv"
              >上传文件</el-button
            >
          </el-upload>
        </div>
        <el-button
          class="bgc-bv"
          size="mini"
          style="margin-left: 10px"
          @click="downLoad()"
          >下载</el-button
        >
      </div>
      <div class="data_box">
        <el-container style="height: 500px; border: 1px solid #eee">
          <el-aside width="290px" style="background-color: rgb(238, 241, 246)">
            <template v-if="list.length">
              <div
                v-for="(el, index) in list"
                :key="index"
                class="flexcb"
                style="padding: 5px 0"
                :class="activeInd == index ? 'active' : ''"
              >
                <div style="display:flex;" @click="chooseItem(el, index)">
                  <div style="margin-right: 5px">{{ index + 1 + "." }}</div>
                  <div style="display:flex;">
                    <el-input
                      :ref="'input_' + index"
                      @blur="saveName(el, index)"
                      size="mini"
                      v-if="el.isEdit"
                      v-model="el.docName"
                       maxlength="50"
                    />
                    <span :title="el.docName" v-else >{{
                      el.docName
                    }}</span>
                  </div>
                </div>

                <div style="flex-shrink: 0">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    :disabled="el.isEdit"
                    @click.stop="() => handleName(el, index)"
                    >重命名</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleDel(el)"
                    >删除</el-button
                  >
                </div>
              </div>
            </template>
            <div style="height: 100%" class="flexdcc" v-else>暂无数据</div>
          </el-aside>
          <el-main>
            <div
              v-if="this.url"
              class="df"
              id="pdf-content7"
              style="height: 100%"
            ></div>
            <p v-else>暂无数据</p>
          </el-main>
        </el-container>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import pdf from "pdfobject";
export default {
  name: "SafetyTrainingHS",
  props: [],
  data() {
    return {
      dialogVisible: false,
      list: [],
      url: "",
      activeInd: 0,
    };
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  methods: {
    showPop(res) {
      this.playload = JSON.parse(res);
      this.queryData();
    },
    // 查询list
    queryData() {
      let params = {
        enterpriseId: this.playload.enterpriseId,
        phaseId: this.playload.phaseId,
        projectId: this.playload.projectId,
        compId: this.playload.compId,
      };
      this.$post("/dm/phase/record/audit/list", params)
        .then((res) => {
          // ;
          if (res.status == "0") {
            this.dialogVisible = true;
            this.list = (res.data || []).map((el) => {
              return {
                ...el,
                isEdit: false,
              };
            });
            if (this.list.length) {
              this.activeInd = 0;
              this.url = this.list[0].fileKey;
              this.preview();
            } else {
              this.url = "";
            }
          }
        })
        .catch(() => {
          this.dialogVisible = false;
        });
    },
    // 预览pdf
    preview() {
      this.$nextTick(() => {
        pdf.embed(this.url, "#pdf-content7", {
          pdfOpenParams: {
            scrollbars: "0",
            toolbar: "0",
            statusbar: "1",
          },
        });
      });
    },
    // 下载全部
    downLoad() {
      this.$post("/dm/phase/record/audit/download", {
        enterpriseId: this.playload.enterpriseId,
        phaseId: this.playload.phaseId,
        projectId: this.playload.projectId,
        compId: this.playload.compId,
      }).then((res) => {
        if (res.status == "0") {
          let list = res.data;
          this.dialogVisible = false;
          if (!this.downloadItems.includes(list.taskId)) {
            this.$store.dispatch("pushDownloadItems", list.taskId);
          } else {
            this.$message.warning(
              "[" + list.fileName + "]已经申请下载,请耐心等待"
            );
          }
          this.closeDocsDialog();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 选择
    chooseItem(el, index) {
      this.url = el.fileKey;
      this.activeInd = index;
      this.preview();
    },
    // 重命名
    handleName(el, index) {
      this.list[index].isEdit = true;
      this.$nextTick(() => {
        this.$refs["input_" + index][0].focus();
      });
    },
    saveName(el, index) {
      let name = el.docName.trim();
      if (name.length < 3) {
        this.$message.error("文件名称不能少于三个字");
        return;
      }
      this.$post("/sys/document/rename", {
        docId: el.docId,
        docName: el.docName,
      }).then((res) => {
        // ;
        this.list[index].isEdit = false;
      });
    },
    // 删除
    handleDel(el) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning",
      })
        .then(() => {
          this.$post("/sys/document/delete", { docId: el.docId }).then(
            (res) => {
              this.queryData();
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 上传文件
    handleAvatarSuccess2(res, list) {
      let formData = new FormData();
      let fileName = res.name; // 文件名
      let ind = fileName.lastIndexOf(".");
      let fileName_1 = fileName.slice(0, ind);
      if (res.size / 1024 / 1024 > 10) {
        this.$message.error("文件大小不能超过10MB");
        return;
      }
      if (fileName_1.length < 3) {
        this.$message.error("文件名称不能少于3个字符");
        return;
      }
      let extension = fileName.replace(/.+\./, ""); // 文件类型
      formData.append("file", res.raw);
      formData.append("folder ", "TEMP");
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.$post("/dm/phase/record/audit/upload/batch", [
            {
              docName: fileName_1,
              fileKey: result.data.fileKey,
              fileSize: res.size,
              fileType: extension,
              enterpriseId: this.playload.enterpriseId,
              phaseId: this.playload.phaseId,
              projectId: this.playload.projectId,
              compId: this.playload.compId,
            },
          ]).then((res) => {
            // ;
            this.queryData();
          });
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "上传失败",
            });
          }, 300);
        });
    },
    downloadTemp() {
      /**
       * 下载模板
       * @param param 参数
       */
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = "/static/生产经营单位安全培训教材会审表.doc";
      link.setAttribute("download", "生产经营单位安全培训教材会审表.doc");
      document.body.appendChild(link);
      link.click();
    },
    doClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="less">
.upload {
  .uploadvideo {
    display: flex;
    .el-upload {
      height: auto !important;
      border: none;
    }
  }
  .el-button {
    height: auto !important;
  }
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 180px;
  font-size: 14px;
}
.active {
  background-color: #d7d7d7;
}
</style>
