//培训日程安排通知/也可称为培训计划
<template>
  <el-dialog
    :visible.sync="dialogVisible"
    title="培训计划"
    top="5%"
    width="75%"
    :center="true"
    :before-close="doClose"
  >
    <div class="archives_box">
      <div class="df" style="justify-content: flex-end; margin-bottom: 10px">
        <!-- <el-button class="bgc-bv" size="mini" @click="handlUpdate()"
          >更新数据</el-button
        > -->
        <el-button
          class="bgc-bv"
          size="mini"
          style="margin-left: 10px"
          @click="doAdd()"
          >新增</el-button
        >
        <el-button
          class="bgc-bv"
          size="mini"
          style="margin-left: 10px"
          @click="doDel()"
          >删除</el-button
        >
        <el-button
          class="bgc-bv"
          size="mini"
          style="margin-left: 10px"
          @click="doDownLoad()"
          >下载</el-button
        >
      </div>
      <div class="table_box">
        <h3 style="text-align: center; margin-bottom: 10px">{{ title }}</h3>
        <h4 style="margin-bottom: 10px">
          单位名称：{{ attrObj.enterpriseName }}
        </h4>
        <div class="ovy-a" style="height: 600px">
          <table
            border="1"
            style="border-collapse: collapse; margin-bottom: 10px"
          >
            <thead>
              <tr>
                <td><span>序号</span></td>
                <td><span>培训组织部门</span></td>
                <td><span>培训日期</span></td>
                <!-- <td><span>培训时间</span></td> -->
                <td><span>开始时间</span></td>
                <td><span>结束时间</span></td>
                <td><span>培训内容</span></td>
                <td><span>培训方式</span></td>
                <td><span>培训对象</span></td>
                <td><span>考核方式</span></td>
                <td><span>地点</span></td>
                <td><span>培训教师</span></td>
                <td><span>经费保障</span></td>
                <td><span>质量评估</span></td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in list"
                :key="index"
                @click="doclickHandle(index, item.trainPlanId)"
                @dblclick="dbclickHangle(index, item)"
                :style="item.classStyle ? 'background:#d7d7d7' : ''"
              >
                <td>
                  <span>{{ index + 1 }}</span>
                </td>
                <td>
                  <span>{{ item.trainDepartName }}</span>
                </td>
                <td>
                  <span>{{ item.trainDate }}</span>
                </td>
                <td>
                  <span>{{ item.startTime }}</span>
                </td>
                <td>
                  <span>{{ item.endTime }}</span>
                </td>
                <td>
                  <span>{{ item.trainContent }}</span>
                </td>
                <td>
                  <span>{{ item.trainType }}</span>
                </td>
                <td>
                  <span>{{ item.trainees }}</span>
                </td>
                <td>
                  <span>{{ item.examType }}</span>
                </td>
                <td>
                  <span>{{ item.trainAddress }}</span>
                </td>
                <td>
                  <span>{{ item.trainTeacher }}</span>
                </td>
                <td>
                  <span>{{ item.fundGuarantee }}</span>
                </td>
                <td>
                  <span>{{ item.qualityAssess }}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="flexac">
            <div
              @click="doEdit('isauthor')"
              class="flexca"
              style="display: flex"
            >
              <span style="min-width: 70px">编制人：</span>
              <span v-if="isauthor"
                >{{ attrObj.author }} <i class="el-icon-edit"></i
              ></span>
              <el-input
                ref="isauthor"
                @blur="doEditOk('isauthor')"
                v-else
                size="mini"
                v-model="attrObj.author"
              ></el-input>
            </div>
            <div
              @click="doEdit('iswriteDate')"
              class="flexca"
              style="display: flex"
            >
              <span style="min-width: 70px">编制日期：</span>
              <span v-if="iswriteDate"
                >{{ attrObj.writeDate }} <i class="el-icon-edit"></i
              ></span>
              <el-date-picker
                ref="iswriteDate"
                v-model="attrObj.writeDate"
                @blur="doEditOk('iswriteDate')"
                type="date"
                v-else
                size="mini"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </div>
            <div
              @click="doEdit('isreviewName')"
              class="flexca"
              style="display: flex"
            >
              <span style="min-width: 70px">审核人：</span>
              <span v-if="isreviewName"
                >{{ attrObj.reviewName }} <i class="el-icon-edit"></i
              ></span>
              <el-input
                ref="isreviewName"
                v-else
                @blur="doEditOk('isreviewName')"
                size="mini"
                v-model="attrObj.reviewName"
              ></el-input>
            </div>
            <div
              @click="doEdit('isreviewDate')"
              class="flexca"
              style="display: flex"
            >
              <span style="min-width: 70px">审核日期：</span>
              <span v-if="isreviewDate"
                >{{ attrObj.reviewDate }} <i class="el-icon-edit"></i
              ></span>
              <el-date-picker
                ref="isreviewDate"
                v-model="attrObj.reviewDate"
                @blur="doEditOk('isreviewDate')"
                type="date"
                placeholder="选择日期"
                v-else
                size="mini"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="提示"
      top="20px"
      :visible.sync="dialogForm"
      width="50%"
      :before-close="handleClose"
      :modal="false"
      :center="true"
    >
      <el-form ref="form" :model="form" label-width="120px">
        <el-form-item label="培训组织部门：">
          <el-input v-model="form.trainDepartName"></el-input>
        </el-form-item>
        <el-form-item label="培训日期：">
          <el-input v-model="form.trainDate"></el-input>
        </el-form-item>
        <el-form-item label="培训时间：">
         <el-date-picker
      v-model="form.trainTime"
      type="daterange"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      format="yyyy-MM-dd"
                value-format="yyyy-MM-dd">
    </el-date-picker>
        </el-form-item>
        <el-form-item label="培训内容：">
          <el-input v-model="form.trainContent"></el-input>
        </el-form-item>
        <el-form-item label="培训方式：">
          <!-- <el-input v-model="form.trainType"></el-input> -->
          <el-select
            v-model="form.trainType"
            clearable
            placeholder="请选择培训方式"
          >
            <el-option label="安知线上平台" value="安知线上平台"></el-option>
            <el-option label="面授讲解" value="面授讲解"></el-option>
            <el-option label="其他" value="其他"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="培训对象：">
           <el-select
              v-model="form.trainees"
              placeholder="请选择"
              size="small"
            >
              <el-option
                v-for="item in Trainees"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          <!-- <el-input v-model="form.trainees"></el-input> -->
        </el-form-item>
        <el-form-item label="考核方式：">
          <el-input v-model="form.examType"></el-input>
        </el-form-item>
        <el-form-item label="地点：">
          <el-input v-model="form.trainAddress"></el-input>
        </el-form-item>
        <el-form-item label="培训教师：">
          <el-input v-model="form.trainTeacher"></el-input>
        </el-form-item>
        <el-form-item label="经费保障：">
          <el-input v-model="form.fundGuarantee"></el-input>
        </el-form-item>
        <el-form-item label="质量评估：">
          <!-- <el-input v-model="form.qualityAssess"></el-input> -->
          <el-select
            v-model="form.qualityAssess"
            clearable
            placeholder="请选择质量评估"
          >
            <el-option label="问卷" value="问卷"></el-option>
            <el-option label="其他" value="其他"></el-option>
          </el-select>
        </el-form-item>
        <div class="flexcc">
          <el-button class="bgc-bv" @click="handleClose()">取 消</el-button>
          <el-button class="bgc-bv" @click="doSave()">保 存</el-button>
        </div>
      </el-form>
    </el-dialog>
  </el-dialog>
</template>

<script>
export default {
  name: "trainDate",
  props: [],
  data() {
    return {
      dialogVisible: false,
      dialogForm: false,
      isauthor: true,
      iswriteDate: true,
      isreviewName: true,
      isreviewDate: true,
      updated: 0,
      attrObj: {
        author: "",
        enterpriseName: "",
        reviewDate: "",
        reviewName: "",
        writeDate: "",
      },
      form: {
        examType: "试卷考核", // 考核方式
        fundGuarantee: "安全生产投入", // 经费保障
        qualityAssess: "", // 质量评估
        trainAddress: "", // 培训地址
        trainContent: "", // 培训内容
        trainDate: "", // 培训日期
        trainDepartName: "", // 培训组织部门
        trainPlanId: "", // id
        trainTeacher: "", // 培训教师
        trainTime: "", // 培训时间
        trainType: "", // 培训方式
        trainees: "", // 培训对象
      },
      trainPlanId: "",
      list: [],
      Trainees: [
        {
          value: "安全管理人员",
          label: "安全管理人员",
        },
        {
          value: "从业人员",
          label: "从业人员",
        },
        {
          value: "维修工",
          label: "维修工",
        },
        {
          value: "承包商",
          label: "承包商",
        },
        {
          value: "特种作业人员",
          label: "特种作业人员",
        },
      ],
    };
  },
  methods: {
    showPop(res) {
      this.playload = JSON.parse(res);
      this.queryData();
    },
    queryData() {
      let params = {
        phaseId: this.playload.phaseId,
        compId: this.playload.compId,
        updated: this.updated,
      };
      this.$post("/dmtrainplan/query", params)
        .then((res) => {
          if (res.status == "0") {
            this.dialogVisible = true;
            this.updated = 0;
            this.title = res.data.title || "";
            this.attrObj = {
              ...this.attrObj,
              ...res.data.attribute,
            };

            this.list = (res.data.plans || []).map((el) => {
              return {
                ...el,
                classStyle: false,
              };
            });
            // console.log(this.list);
          }
        })
        .catch(() => {
          this.dialogVisible = false;
        });
    },
    // 编辑底部
    doEdit(dataName) {
      this[dataName] = false;
      console.log(this.attrObj);
      // console.log(dataName);
      this.$nextTick(() => {
        this.$refs[dataName].focus();
      });
    },
    doEditOk(dataName) {
      this.$post("/dmtrainplan/updateAttributeData", {
        author: this.attrObj.author,
        phaseId: this.playload.phaseId,
        compId: this.playload.compId,
        reviewDate: this.attrObj.reviewDate,
        reviewName: this.attrObj.reviewName,
        writeDate: this.attrObj.writeDate,
      }).then((res) => {
        // ;
        // console.log(dataName);
        this[dataName] = true;
      });
    },
    // 新增
    doAdd() {
      this.list = [this.form, ...this.list];
    },
    doclickHandle(index, trainPlanId) {
      this.trainPlanId = trainPlanId;
      this.list.forEach((el, ind) => {
        if (index == ind) {
          el.classStyle = true;
        } else {
          el.classStyle = false;
        }
      });
    },
    // 双击选中
    dbclickHangle(index, item) {
      // console.log(index, item);
      this.dialogForm = true;
      this.form = {
        ...item,
        // trainTime:[item.startTime.replaceAll('/','-'),item.endTime.replaceAll('/','-')],
         examType: item.examType || "试卷考核", // 考核方式
        fundGuarantee: item.fundGuarantee ||"安全生产投入", // 经费保障
      };
      if(item.startTime) {
         this.form.trainTime=[item.startTime.replaceAll('/','-'),item.endTime.replaceAll('/','-')]
      }
    },
    check() {
      let stu = true;
      let {
        examType,
        fundGuarantee,
        qualityAssess,
        trainAddress,
        trainContent,
        trainDate,
        trainDepartName,
        trainTeacher,
        trainTime,
        trainType,
        trainees,
      } = this.form;
      let val =
        examType +
        fundGuarantee +
        qualityAssess +
        trainAddress +
        trainContent +
        trainDate +
        trainDepartName +
        trainTeacher +
        trainTime +
        trainType +
        trainees;
      if (val.trim() == "") {
        this.$message.error("请至少填写一项");
        stu = false;
      }
      let textArr = [];
      for (const i in this.form) {
        if (this.form[i].length > 50) {
          stu = false;
          this.$message.error("每一项字数不能超过50个字");
          break;
        }
      }
      return stu;
    },

    doSave() {
      if (!this.check()) {
        return;
      }
      let params = {
        // ...this.form,
        trainDepartName:this.form.trainDepartName,
trainDate:this.form.trainDate,
trainContent:this.form.trainContent,
trainType:this.form.trainType,
trainees:this.form.trainees,
examType:this.form.examType,
trainAddress:this.form.trainAddress,
trainTeacher:this.form.trainTeacher,
fundGuarantee:this.form.fundGuarantee,
qualityAssess:this.form.qualityAssess,
startTime:this.form.trainTime[0],
endTime:this.form.trainTime[1],
        phaseId: this.playload.phaseId,
        compId: this.playload.compId,
      };
      if (params.trainTime) {
        delete params.trainTime;
      }
      if (params.createTime) {
        delete params.createTime;
      }
      if (params.updateTime) {
        delete params.updateTime;
      }
      if(this.form.trainPlanId) {
        params.trainPlanId = this.form.trainPlanId
      }
      this.$post(
        `/dmtrainplan/${this.form.trainPlanId ? "modify" : "insert"}`,
        params
      ).then((res) => {
        if (res.status == "0") {
          this.queryData();
          this.handleClose();
        }
      });
    },
    doDel() {
      if (!this.trainPlanId) {
        this.$message({
          type: "warning",
          message: "请选择一行进行删除",
        });
        return;
      }
      this.$confirm("此操作将删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning",
      })
        .then(() => {
          this.$post("/dmtrainplan/delete", {
            trainPlanId: this.trainPlanId,
          }).then((res) => {
            // ;
            if (res.status == "0") {
              this.trainPlanId = "";
              this.queryData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    doDownLoad() {
      //getTrainPlanReport
      this.$post("/dmtrainplan/getTrainPlanReport", {
        phaseId: this.playload.phaseId,
        compId: this.playload.compId,
        updated: 0,
      }).then((res) => {
        // ;
        if (res.status == "0") {
          let url = res.data.downloadUrl || "";
          window.open(url);
        }
      });
    },
    handleClose() {
      this.form = {
        examType: "试卷考核", // 考核方式
        fundGuarantee: "安全生产投入", // 经费保障
        qualityAssess: "", // 质量评估
        trainAddress: "", // 培训地址
        trainContent: "", // 培训内容
        trainDate: "", // 培训日期
        trainDepartName: "", // 培训组织部门
        trainPlanId: "", // id
        trainTeacher: "", // 培训教师
        trainTime: "", // 培训时间
        trainType: "", // 培训方式
        trainees: "", // 培训对象
      };
      this.dialogForm = false;
    },
    handlUpdate() {
      this.updated = 1;
      this.queryData();
    },
    doClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="less">
.table_box {
  table {
    width: 100%;
    max-width: 100%;
    td {
      max-width: 110px;
    }
  }
  .el-input__icon.el-icon-date {
    position: absolute;
  }
  .el-icon-date:before {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>