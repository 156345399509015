// 本期综合考评报告
<template>
  <el-dialog
    :visible.sync="dialogVisible"
    title="培训影像资料"
    top="5%"
    width="50%"
    :center="true"
    :before-close="doClose"
  >
    <div class="archives_box">
      <div class="df" style="justify-content: flex-end; margin-bottom: 10px">
        <el-button
          class="bgc-bv"
          style="margin: 0 10px"
          size="mini"
          @click="handlUpdate()"
          >更新数据</el-button
        >
        <el-button
          class="bgc-bv"
          style="margin: 0 10px"
          size="mini"
          @click="doDownLoad()"
          >下载</el-button
        >
      </div>
      <div id="pdf-content2" style="height: 600px"></div>
    </div>
  </el-dialog>
</template>

<script>
import pdf from "pdfobject";
import { mapGetters } from "vuex";
export default {
  name: "AssessmentReport",
  props: [],
  data() {
    return {
      dialogVisible: false,
      updated: 0,
    };
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  methods: {
    showPop(res) {
      this.playload = JSON.parse(res);
      this.queryData();
    },
    queryData() {
      let params = {
        compId: this.playload.compId,
        enterpriseId: this.playload.enterpriseId,
        phaseId: this.playload.phaseId,
        updated: this.updated,
      };
      this.$post("/documentcenter/getVideoMaterialsAsync", params)
        .then((res) => {
          if (res.status == "0") {
            this.dialogVisible = true;
            let taskIds = [];
            taskIds.push(res.data.taskId);
            this.queryDownloadState(taskIds);
          }
        })
        .catch(() => {
          this.dialogVisible = false;
        });
    },
    queryDownloadState(taskIds) {
      this.$post("/sys/download/queryDownloadState", { taskIds }).then(
        (ret) => {
          if (ret.data[0].taskState == "10") {
            if (this.updated == 1) {
              this.$message("正在更新中,请稍后");
            }

            setTimeout(() => {
              this.queryDownloadState(taskIds);
            }, 300);
          } else if (ret.data[0].taskState == "20") {
            if (ret.data[0].downloadList) {
              if (ret.data[0].downloadList[0].key) {
                this.$nextTick(() => {
                  pdf.embed(ret.data[0].downloadList[0].key, "#pdf-content2", {
                    pdfOpenParams: {
                      scrollbars: "0",
                      toolbar: "0",
                      statusbar: "1",
                    },
                  });
                });
              }
            } else {
              this.$message({
                message: "更新失败",
                type: "error",
              });
            }
          } else {
            this.$message({
              message: ret.data[0].errMsg,
              type: "error",
            });
          }
        }
      );
    },
    handlUpdate() {
      this.updated = 1;
      let params = {
        compId: this.playload.compId,
        enterpriseId: this.playload.enterpriseId,
        phaseId: this.playload.phaseId,
        updated: this.updated,
      };
      this.$post("/documentcenter/getVideoMaterialsAsync", params)
        .then((res) => {
          if (res.status == "0") {
            let taskIds = [];
            taskIds.push(res.data.taskId);
            this.queryDownloadState(taskIds);
          }
        })
        .catch(() => {
          this.dialogVisible = false;
        });
    },
    doDownLoad() {
      this.updated = 0;
      this.$confirm(
        "根据有关部门业务开展需要，相关数据会涉及个人信息、照片等敏感信息，导出数据仅限于业务范围内使用，不得用于其他用途，如因个人原因导致数据泄露，由个人承担法律责任。",
        "提示",
        {
          showClose: true, //是否显示右上角关闭按钮
          showCancelButton: false, //是否显示取消按钮
          closeOnClickModal: false, //是否可通过点击遮罩关闭 MessageBox
          confirmButtonText: "同意并继续",
          confirmButtonClass: "bgc-bv",
        }
      )
        .then(() => {
          ///documentcenter/downVideoMaterials
          this.$post("/documentcenter/downVideoMaterialsAsync", {
            compId: this.playload.compId,
            enterpriseId: this.playload.enterpriseId,
            phaseId: this.playload.phaseId,
            updated: this.updated,
          }).then((res) => {
            if (!this.downloadItems.includes(res.data.taskId)) {
              this.$store.dispatch("pushDownloadItems", res.data.taskId);
            } else {
              this.$message.warning(
                "[" + res.data.fileName + "]已经申请下载,请耐心等待"
              );
            }
          });
        })
        .catch(() => {});
    },
    doClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style>
</style>